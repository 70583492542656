<template>
    <div class="bg-white shadow-md rounded border border-rose-600 px-8 pt-6 pb-8 mb-4 w-1/3">
        <div v-if="getErrorMessage" class="flex bg-pink-200 p-2 rounded">
            Hata: {{ getErrorMessage }}
        </div>
        <div class="flex flex-wrap items-center justify-left w-full py-4 md:py-0 border-b border-rose-300">
            <LockClosedIcon class="h-10 text-rose-600"></LockClosedIcon>
            <h3 class="text-2xl text-rose-700">Giriş Ekranı</h3>
        </div>
        <div class="mb-4">
            <label class="block text-gray-700 text-md font-bold mb-2" for="username">Kullanıcı Adınız</label>
            <input 
            class="shadow appearance-none border border-rose-500 bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight" 
            id="username" type="text"
            v-model="username"
            >
        </div>
        <div class="mb-6">
            <div><label class="block text-gray-700 text-md font-bold mb-2" for="password">Şifre</label></div>
            <div><input class="shadow appearance-none border border-rose-500 bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight" 
            id="password" type="password"
            v-model="password"
            ></div>
        </div>
        <div class="flex items-center justify-between">
            <button class="bg-rose-500 font-sans text-white rounded-md p-2 hover:bg-rose-400"
            @click="loginForm()"
            >Sisteme Giriş</button>
        </div>
    </div>
</template>

<script>

import {LockClosedIcon} from '@heroicons/vue/solid'
import { mapActions, mapGetters } from 'vuex';

export default {
    data(){
        return {
            username: '',
            password: ''
        }

    },
    computed: {
        ...mapGetters('userModule',['getErrorMessage','getToken'])

    },
    components:{
        LockClosedIcon
    },
    methods: {
        ...mapActions('userModule',['login']),
        loginForm(){
            this.login({
                username: this.username,
                password: this.password
            })
        }
    }
}
    

</script>