<template>
<div class="rounded shadow-lg p-6 m-4 border border-gray-300 flex flex-col gap-1">
    <div class="flex flex-start rounded-sm border border-slate-200 p-2">
        <div class="ml-2 mr-2 w-22 text-right">
            Yaş
        </div>
        <div class="">
            
            <input v-model="age"  class="w-16 shadow text-sm border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" type="number" name="age" id="age">
        </div>
        <div class="ml-2 mr-2 w-22 text-right">
            Cinsiyet
        </div>
        <div class="relative">
                <select v-model="sex" class="w-full border px-4 pr-8 py-2 rounded bg-gray-100 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" id="select-item">
                    <option value=0 selected>Kadın</option>
                    <option value=1>Erkek</option>
                </select>
        </div>
        <div class="ml-2 mr-2 w-22 text-right">
            QTc<br>(ms)
        </div>
        <div class="">
            <input v-model="QTInterval" class="w-20 shadow text-sm border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" type="number" name="qtc" id="qtc">
        </div>
        <div class="ml-2 mr-2 w-22 text-right">
            VKİ<br>(kg/m<sup>2</sup>)
        </div>
        <div class="">
            <input v-model = "BMI" class="w-20 shadow text-sm border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" type="number" name="bmi" id="bmi">
        </div>
    </div>
    <div class="flex flex-start rounded-sm border border-slate-200 p-2">
        <div class="ml-2 mr-2 w-22 text-right">
            Potasyum<br>(mmol/L)
        </div>
        <div class="mr-2">
            <input v-model="potassium" class="w-16 shadow text-sm border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" type="number" name="age" id="age">
        </div>
        <div class="ml-2 mr-2 w-22 text-right">
            Kalsiyum<br>(mmol/L)
        </div>
        <div class="mr-2">
            <input v-model="calcium" class="w-16 shadow text-sm border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" type="number" name="age" id="age">
        </div>
        <div class="ml-2 mr-2 w-22 text-right">
            CRP<br>(mg/L)
        </div>
        <div class="">
            <input v-model="CRPvalue" class="w-16 shadow text-sm border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" type="number" name="age" id="age">
        </div>
        <div class="ml-2 mr-2 w-22 text-right">
            eGFR<br>(ml/dk)
        </div>
        <div class="">
            <input v-model="eGFR" class="w-16 shadow text-sm border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700 focus:border-blue-500 focus:shadow-outline outline-none appearance-none" type="number" name="age" id="age">
        </div>

    </div>
    <div class="flex flex-cols-2 gap-2">
        <div>
            <div class="grid grid-rows border border-slate-200 p-2 items-start">
            <!-- Akut MI -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-24">
                    Akut MI
                </div>
                <div>
                <span class="ml-4">
                    <input  v-model="acuteMI" v-bind:checked="acuteMI==1" id="akutmi-option-var" name = "akutmi" type="radio" value="1" class="mr-2">
                    <label for="akutmi-option-var">Var</label>
                </span>
                <span class="ml-4">
                    <input  v-model="acuteMI" v-bind:checked="acuteMI==0" id="akutmi-option-yok" name = "akutmi" type="radio" value="0" class="mr-2">
                    <label for="akutmi-option-yok">Yok</label>
                </span>

                </div>
            </div>
            <!-- Akut MI son --->
            <!-- Aritmi -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-24">
                    Aritmi
                </div>
                <div>
                    <span class="ml-4">
                        <input  v-model="arythmia" id="aritmi-option-var" name = "aritmi" type="radio" value="1" class="mr-2">
                        <label for="aritmi-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input v-model="arythmia" id="aritmi-option-yok" name = "aritmi" type="radio" value="0" class="mr-2">
                        <label for="aritmi-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Aritmi son -->
            <!-- Hipertansiyon -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-24">
                    Hipertansiyon
                </div>
                <div>
                    <span class="ml-4">
                        <input  v-model="hypertension" id="hipertansiyon-option-var" name = "hipertansiyon" type="radio" value="1" class="mr-2">
                        <label for="hipertansiyon-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input v-model="hypertension" id="hipertansiyon-option-yok" name = "hipertansiyon" type="radio" value="0" class="mr-2">
                        <label for="hipertansiyon-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Hipertansiyon son -->
            <!-- Kalp yetmezliği -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    Kalp Yetmezliği
                </div>
                <div>
                    <span class="ml-4">
                        <input v-model="heartfailure" id="kalpyetmezligi-option-var" name = "kalpyetmezligi" type="radio" value="1" class="mr-2">
                        <label for="kalpyetmezligi-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input  v-model="heartfailure" id="kalpyetmezligi-option-yok" name = "kalpyetmezligi" type="radio" value="0" class="mr-2">
                        <label for="kalpyetmezligi-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Kalp yetmezliği son --> 
            <!-- Kardiyomiyopati -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    Kardiyomiyopati
                </div>
                <div>
                    <span class="ml-4">
                        <input  v-model="cardiomyopathia" id="kardiyomiyopati-option-var" name = "kardiyomiyopati" type="radio" value="1" class="mr-2">
                        <label for="kardiyomiyopati-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input  v-model="cardiomyopathia" id="kardiyomiyopati-option-yok" name = "kardiyomiyopati" type="radio" value="0" class="mr-2">
                        <label for="kardiyomiyopati-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Kardiyomiyopati son --> 
            <!-- Diyabet -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    Diyabet
                </div>
                <div>
                    <span class="ml-4">
                        <input v-model="diabetes" id="diyabet-option-var" name = "diyabet" type="radio" value="1" class="mr-2">
                        <label for="diyabet-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input  v-model="diabetes" id="diyabet-option-yok" name = "diyabet" type="radio" value="0" class="mr-2">
                        <label for="diyabet-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Diyabet son --> 
            <!-- Nörolojik Hastalık -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    Nörolojik Hastalık
                </div>
                <div>
                    <span class="ml-4">
                        <input  v-model="neurologicDiseases" id="norolojikhastalik-option-var" name = "norolojikhastalik" type="radio" value="1" class="mr-2">
                        <label for="norolojikhastalik-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input v-model="neurologicDiseases" id="norolojikhastalik-option-yok" name = "norolojikhastalik" type="radio" value="0" class="mr-2">
                        <label for="norolojikhastalik-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Diyabet son --> 
            <!-- Karaciğer Yetmezliği -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    K.c. Yetmezliği
                </div>
                <div>
                    <span class="ml-4">
                        <input  v-model="liverfailure" id="karacigeryetmezligi-option-var" name = "karacigeryetmezligi" type="radio" value="1" class="mr-2">
                        <label for="karacigeryetmezligi-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input  v-model="liverfailure" id="karacigeryetmezligi-option-yok" name = "karacigeryetmezligi" type="radio" value="0" class="mr-2">
                        <label for="karacigeryetmezligi-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Karaciğer Yetmezliği son --> 
            <!-- Sepsis -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    Sepsis
                </div>
                <div>
                    <span class="ml-4">
                        <input v-model="sepsis" id="sepsis-option-var" name = "sepsis" type="radio" value="1" class="mr-2">
                        <label for="thyroid-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input  v-model="sepsis" id="sepsis-option-yok" name = "sepsis" type="radio" value="0" class="mr-2">
                        <label for="sepsis-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Sepsis son --> 

            <!-- Tiroid Bozukluğu -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    Tiroid Bozukluğu
                </div>
                <div>
                    <span class="ml-4">
                        <input v-model="thyroidDisease" id="thyroid-option-var" name = "thyroid" type="radio" value="1" class="mr-2">
                        <label for="thyroid-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input v-model="thyroidDisease" id="thyroid-option-yok" name = "thyroid" type="radio" value="0" class="mr-2">
                        <label for="thyroid-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Tiroid Bozukluğu son --> 
            <!-- Tütün Kullanımı -->
            <div class="text-left mr-2 grid grid-cols-2 w-64">
                <div class="w-32">
                    Tütün Kullanımı
                </div>
                <div>
                    <span class="ml-4">
                        <input  v-model = "tobacco" id="tobacco-option-var" name = "tobacco" type="radio" value="1" class="mr-2">
                        <label for="tobacco-option-var">Var</label>
                    </span>
                    <span class="ml-4">
                        <input  v-model = "tobacco"  id="tobacco-option-yok" name = "tobacco" type="radio" value="0" class="mr-2">
                        <label for="tobacco-option-yok">Yok</label>
                    </span>
                </div>
            </div>
            <!-- Tütün Kullanımı son -->
        </div>
 
    </div>
       <div class="rounded border border-slate-200 p-2 items-start">
            <!---- Skor Hesaplaa --->
            <score-results></score-results> 
            
        </div>
    
    </div>
    <div class="flex flex-start rounded-sm border border-slate-200">
        <patient-drug-list></patient-drug-list>
    </div>



    
    
</div>
</template>

<script>
    import PatientDrugList from '@/components/PatientDrugList.vue'
    import ScoreResults from '@/components/ScoreResults.vue'
    import {mapActions, mapGetters } from 'vuex'

export default {
    components: {
        PatientDrugList,
        ScoreResults
    },
    computed: {
        ...mapGetters('calculationModule',[
        'getAge', 
        'getSex',
        'getQTInterval',
        'getPotassium',
        'getCalcium',
        'getAcuteMI',
        'getSepsis',
        'getHeartFailure',
        'getTobacco',
        'getBMI',
        'getCardiomyopathia',
        'getHypertension',
        'getArythmia',
        'getThyroidDisease',
        'getLiverFailure',
        'getNeurologicDiseases',
        'getDiabetes', 'getCRPvalue', 'geteGFR'

        ]),
        
        age: {
            get() {
                return this.getAge
            },
            set(value) {
                this.updateAge(value)
            }
        },
        sex: {
            get(){
                return this.getSex;
            },
            set(value){
                this.updateSex(value)
            }
        },
        QTInterval: {
            get(){
                return this.getQTInterval;
            },
            set(value){
                this.updateQTInterval(value)
            }
        },
        potassium: {
            get(){
                return this.getPotassium;
            },
            set(value){
                if (value == '') {
                    value = null

                }
                this.updatePotassium(value)
            }
        },
        calcium: {
            get(){
                return this.getCalcium;
            },
            set(value){
                this.updateCalcium(value)
            }
        },
        acuteMI:{
            get(){
                return this.getAcuteMI
            },
            set(value){
                this.updateAcuteMI(value)
            }
        },
        sepsis:{
            get(){
                return this.getSepsis;
            },
            set(value){
                this.updateSepsis(value)
            }
        },
        heartfailure:{
            get(){
                return this.getHeartFailure
            },
            set(value){
                this.updateHeartFailure(value)
            }
        }, 
        tobacco : {
            get(){
                return this.getTobacco;
            },
            set(value){
                this.updateTobacco(value)
            }

        },
        BMI: {
            get(){
                return this.getBMI;
            },
            set(value){
                this.updateBMI(value)
            }

        },
        cardiomyopathia: {
            get(){
                return this.getCardiomyopathia
            },
            set(value){
                this.updateCardiomyopathia(value)
            }

        },
        hypertension: {
            get(){
                return this.getHypertension;
            },
            set(value){
                this.updateHypertension(value)
            }
        },
        arythmia: {
            get(){
                return this.getArythmia;
            },
            set(value){
                this.updateArythmia(value)
            }
        },
        thyroidDisease: {
            get(){
                return this.getThyroidDisease;
            },
            set(value){
                this.updateThyroidDisease(value)
            }   
        },
        liverfailure: {
            get(){
                return this.getLiverFailure;
            },  
            set(value){
                this.updateLiverFailure(value)
            }
        },
        neurologicDiseases: {
            get(){
                return this.getNeurologicDiseases;
            },
            set(value){
                this.updateNeurologicDiseases(value)
            }
        },
        diabetes: {
            get(){
                return this.getDiabetes;
            },
            set(value){
                this.updateDiabetes(value)
            }
        },
        CRPvalue: {
            get(){
                return this.getCRPvalue;
            },
            set(value){
                this.updateCRPvalue(value)
            }
        },
        eGFR: {
            get(){
                return this.geteGFR;
            },
            set(value){
                this.updateeGFR(value)
            }
        }


        
        
    },
    methods: {
        ...mapActions('calculationModule', 
        ['updateAge', 'updateSex', 'updateQTInterval', 'updatePotassium',
        'updateCalcium', 'updateAcuteMI', 'updateSepsis',
        'updateHeartFailure', 'updateTobacco','updateBMI', 'updateCardiomyopathia',
        'updateHypertension', 'updateArythmia','updateThyroidDisease','updateLiverFailure',
        'updateNeurologicDiseases','updateDiabetes', 'updateCRPvalue', 'updateeGFR'
        ]),
        
    }
}



</script>
