import api from '../api';
import router from '@/router'


const state = {
    token: null,
    isLogged: false,
    errorMessage: null
}

const getters = {
    getToken: state => state.token,
    getisLogged: state => state.isLogged,
    getErrorMessage: state => state.errorMessage
}

const mutations = {
    setToken: (state, token) => {
        state.token = token
        
    },
    setisLogged: (state, isLogged) => {
        state.isLogged = isLogged
    },
    setErrorMessage: (state, payLoad)=>{
        state.errorMessage = payLoad;
    }
}

const actions = {
    login({commit}, payLoad){
        let formData = new FormData();
        formData.append('username', payLoad.username);
        formData.append('password', payLoad.password);
        let headers = {'Content-type': 'multipart/form-data'};
        api.post('user/',formData, headers).then((response) => {

            if(response.status == 200 ){
                commit('setToken', response.data.access_token);
                commit('setisLogged', true);
                commit('setErrorMessage', null)
                router.push({name: 'Home'})

            }
        }).catch(error =>{
            
            commit('setErrorMessage', error.response.data.detail);
        })
    },
    enableLogged({commit}, payLoad){
        commit('setisLogged', payLoad)
    }
}

const userModule =  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default userModule;
