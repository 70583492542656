<template>
  <footer class="h-20 bg-slate-100 border-t border-gray-300 bg-slate-200 mb-4">
      
          <p class="text-base text-gray-400 m-4">&copy; 2022 Doç. Dr. Mehmet Arun
          <br>Bu yazılım geliştirme/test aşamasındadır. Herhangi bir klinik karar için kullanılması uygun değildir. Veritabanı ve yazılım tasarım ve telif hakları saklıdır.
          </p>
          

      
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>