<template>
    <div class="flex justify-center">
        <LoginForm></LoginForm>
    </div>

    
</template>


<script>

import LoginForm from '@/components/LoginForm.vue'

export default {
    name: 'Login',
    components: {
        LoginForm
    }

}

</script>
