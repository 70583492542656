<template>
    <div class="h-full flex flex-col gap-2">
        <div class="bg-emerald-700 h-1/2 rounded-lg">
            <div class="flex flex-row">
                <div class="p-8 items-center font-sans font-bold text-3xl text-white ">Tisdale</div>
                <div class="p-4 items-center font-sans font-bold text-3xl text-white text-center"> {{ getTisdaleScore }}<br>({{ getTisdaleScoreExplaination }})</div>
            </div> 
        </div>
        <div class="bg-cyan-600 h-1/2 rounded-lg">
            <div class="flex flex-row">
                <div class="p-8 items-center font-sans font-bold text-3xl text-white ">RISQ-PATH</div>
                <div class="p-4 items-center font-sans font-bold text-3xl text-white text-center">{{ getRisqPathScore }}<br>({{ getRisqPathExplanation }})</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';



export default{
    data(){
        return {
            tisdaleScore: 0
        }
    },
    computed: {
        ...mapGetters('calculationModule',[
            'getTisdaleScore','getTisdaleScoreExplaination', 'getRisqPathScore','getRisqPathExplanation'
        ])
      
    },
    mounted(){
        
    },
    methods: {
        calculateTisdale: function(){
            let score = 0
            
            if(this.getAge >= 68){
                score =+1;
            }
            this.tisdaleScore = score;
            
        }
    }
}

</script>
