<template>

<div class="p-2 text-sm w-full">
                <button 
                class="text-white rounded bg-pink-500 p-2 font-sans font-semibold shadow-lg hover:bg-pink-700 outline-none active:outline-none focus:outline-none mb-2 "
                @click="updateToggleModal(!getToggleModal)">İlaç Ekle </button>

            <table class="divide-y divide-gray-300 font-sans text-xs rounded border border-slate-200 min-w-full ">
            <thead class="bg-gray-50">
                <tr class="">
                    <th></th>
                    <th class="px-4 py-2 text-xs text-gray-600"><span class="text-xs">Ürün Adı</span></th>
                    <th class="px-4 py-2 text-xs text-gray-600"><span class="text-xs">Etkin Madde</span></th>
                    <th class="px-4 py-2 text-xs text-gray-600"><span class="text-xs">ATC Kodu</span></th>
                    
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-300">
                <tr v-for="item in sortedArray"  :key="item.uuid" v-bind:class="{'bg-rose-600 text-white': item.riskcategory == 1, 'bg-orange-400 text-slate-900': item.riskcategory == 2,'bg-yellow-200 text-slate-800': item.riskcategory == 3}">
                    <td><button 
                    class="bg-pink-600 p-1 rounded ml-1" 
                    @click="deleteList(item.uuid)"
                    ><MinusCircleIcon class="text-white h-4"></MinusCircleIcon></button></td>
                    <td class="px-4 py-2 text-sm "><span class="text-xs">{{ item.urunadi }}</span></td>
                    <td class="px-4 py-2 text-sm "><span class="text-xs">{{ item.etkinmadde }}</span></td>
                    <td class="px-4 py-2 text-sm "><span class="text-xs">{{ item.atckodu }}</span></td>
                </tr>

            </tbody>
        </table>
          <div class=" pt-1 bg-sky-200 rounded pl-4 pr-4 pt-1 pb-1">
            <div class="flex flex-cols-3 mb-2 gap-2">
              <div class="text-xs font-sans">Renk<br>açıklamaları</div>
              <div class="text-xs bg-rose-600 text-white p-1 rounded">Kategori 1</div>
              <div class="text-xs bg-orange-400 text-slate-900 p-1 rounded">Kategori 2</div>
              <div class="text-xs bg-yellow-200 text-slate-800 p-1 rounded">Kategori 3</div>
            </div>

          </div>
</div>
    
</template>


<script>
import {mapGetters, mapActions} from 'vuex'
import {MinusCircleIcon} from '@heroicons/vue/solid'

export default {
    name:'PatientDrugList',
    components:{
        MinusCircleIcon
    },
    methods:{
        ...mapActions('patientdruglistModule', ['deleteList']),
        ...mapActions(['updateToggleModal'])
    },
    
    computed: {
        ...mapGetters('patientdruglistModule', ['getpatientdrugList']),
        ...mapGetters(['getToggleModal']),
        sortedArray: function(){
            let copyArray = this.getpatientdrugList.slice()
            function compare(a,b){
                let adA = a.urunadi.toUpperCase();
                let adB = b.urunadi.toUpperCase();
                if (adA < adB) {
                    return -1;
                }

                if (adA > adB) {
                    return 1;
                }
                return 0;

            }
            return copyArray.sort(compare);
        }
    },
    

}
</script>