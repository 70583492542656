<template>
    <div class="flex flex-col">
        <div>
            <div class="flex flex-auto space-x-2 ">
                <div>
                    <input v-model = "searchItem" type="text" class="rounded w-full border border-amber-200 mb-2 shadow-sm bg-amber-50">
                </div>
                <div>
                    <button class="bg-blue-500 text-white rounded-md p-1 text-sm hover:bg-blue-400 flex items-center pl-2 pr-2"
                    @click="setSearchTerm(this.searchItem)">
                        <SearchIcon class="h-4 text-white font-semibold pr-2"></SearchIcon>
                        Ara
                    </button>
                </div>
            </div>
        </div>   
        <div v-if= "geterrorMessage" class="rounded bg-rose-300 text-pink-700 font-sans font-semibold p-2 mb-1">
            {{ geterrorMessage }}
        </div>

    </div>
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import {mapActions, mapGetters} from 'vuex'
export default {
    data(){
        return {
            searchItem: ''
        }
    },
    beforeMount(){
        this.setSearchTerm('ab')
    },
    components: {
        SearchIcon
    },
    methods: {
        ...mapActions('ruhsatliurunModule',['setSearchTerm'])
    },
    computed:{
        ...mapGetters('ruhsatliurunModule',['geterrorMessage'])
    }
    
}

</script>
