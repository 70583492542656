<template>
    <div class="w-full flex flex-wrap items-center bg-red-700 text-2xl text-white font-sans font-semibold mb-4 p-4">
        <HeartIcon class="h-14 text-white"></HeartIcon>
        <span>QT Aralığı Uzama Riski Hesaplayıcı</span>
        <span class="text-gray-200 text-sm font-mono mx-4 ">v0.8.0</span>

    </div>
</template>


<script>
import { HeartIcon } from '@heroicons/vue/solid'

export default {
    name: 'Header',
    components: {
        HeartIcon
    },
    
}
</script>