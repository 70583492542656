<template>
  <div class="mx-auto items-center justify-center w-full">
    
    
  <div v-if ="getToggleModal" class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50 ">
    <div class="relative mx-auto w-3/4 p-4">
      <div class="bg-white w-full rounded shadow-2xl p-8">
        <RuhsatliUrunList></RuhsatliUrunList>
        <div class="flex flex-cols gap-4">
          <div>
            <button class="rounded bg-green-700 text-white font-sans font-semibold shadow-lg p-2" @click="updateToggleModal(!getToggleModal)">Kapat</button>
          </div>
          <div class=" pt-1 bg-sky-200 rounded pl-4 pr-4 pt-1 pb-1">
            <div class="flex flex-cols-3 mb-2 gap-2">
              <div class="text-xs font-sans">Renk<br>açıklamaları</div>
              <div class="text-xs bg-rose-600 text-white p-1 rounded">Kategori 1</div>
              <div class="text-xs bg-orange-400 text-slate-900 p-1 rounded">Kategori 2</div>
              <div class="text-xs bg-yellow-200 text-slate-800 p-1 rounded">Kategori 3</div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

  <div v-if="getToggleModal"
  class="absolute z-40 inset-0 opacity-25 bg-black">
  
  </div>
  
  <PatientData></PatientData>
  </div>
</template>

<script>
    import RuhsatliUrunList from '@/components/RuhsatliUrunList.vue'
    import router from '@/router';
    import PatientData from '@/components/PatientData'
    import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Home',
  components:{
    RuhsatliUrunList,
    
    PatientData
  },
  computed:{
    ...mapGetters(['getToggleModal']),
    ...mapGetters('userModule', ['getisLogged']),
    ...mapGetters('patientdruglistModule',['getpatientdrugList'])
  },
  methods:{
    ...mapActions(['updateToggleModal'])
  },
  mounted(){
    if(!this.getisLogged){
      router.push({name: 'Login'})
    }

  }
}
</script>
