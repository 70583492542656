import { createStore } from 'vuex'
import userModule from './modules/user'
import ruhsatliurunModule from './modules/ruhsatliurunler'
import patientdruglistModule from './modules/patientdruglist'
import createPersistedState from 'vuex-persistedstate'
import calculationModule from './modules/riskcalculator'

export default createStore({
  namespaced: true,
  state: {
    toggleModal:false,
  },
  getters:{
    getToggleModal(state){
      return state.toggleModal
    }
  },
  mutations: {
    setToggleModal(state,payload){
      state.toggleModal = payload
    }

  },
  actions: {
    updateToggleModal({commit},payload){
      commit('setToggleModal',payload)
    }
  },
  modules: {
    userModule, 
    ruhsatliurunModule,
    patientdruglistModule,
    calculationModule
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage // sekme kapandığında bilgiler silinsin kalmasın
  })]
})
