<template>
    <search-box></search-box>
    <div class="flex flex-col border border-gray-300 mb-4 h-60 overflow-y-scroll">
        <table class="divide-y divide-gray-300 font-sans">
            <thead class="bg-gray-50">
                <tr class="">
                    <th></th>
                    <th class="px-4 py-2 text-xs text-gray-600">Barkod</th>
                    <th class="px-4 py-2 text-xs text-gray-600">Ürün Adı</th>
                    <th class="px-4 py-2 text-xs text-gray-600">Etkin Madde</th>
                    <th class="px-4 py-2 text-xs text-gray-600">ATC Kodu</th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-300">
                <tr v-for="item in getruhsatliurunList"  :key="item.uuid"  v-bind:class="{'bg-rose-600 text-white': item.riskcategory == 1, 'bg-orange-400 text-slate-900': item.riskcategory == 2,'bg-yellow-200 text-slate-800': item.riskcategory == 3}">
                    <td><button 
                    class="bg-lime-600 p-1 rounded ml-1" 
                    @click="updateSelectedUrun(item)"
                    ><PlusCircleIcon class="text-white h-4"></PlusCircleIcon></button></td>
                    <td class="px-4 py-2 text-sm ">{{ item.barkod }}</td>
                    <td class="px-4 py-2 text-sm ">{{ item.urunadi }}</td>
                    <td class="px-4 py-2 text-sm ">{{ item.etkinmadde }}</td>
                    <td class="px-4 py-2 text-sm ">{{ item.atckodu }}</td>
                </tr>

            </tbody>
        </table>

    </div>
</template>

<script>
import {PlusCircleIcon} from '@heroicons/vue/solid'
import RuhsatliUrunSearch from './RuhsatliUrunSearch.vue'
import {mapGetters, mapActions} from 'vuex'
export default {
    name: 'RuhsatliUrunList',
    components: {
        searchBox: RuhsatliUrunSearch,
        PlusCircleIcon
    },
    computed: {
        ...mapGetters('ruhsatliurunModule', ['getruhsatliurunList'])

    }, 
    methods: {
        ...mapActions('ruhsatliurunModule', ['updateSelectedUrun'])
    },
}



</script>