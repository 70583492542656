const state = {
    patientDrugList: [],
}

const getters = {
    getpatientdrugList(state){
        return state.patientDrugList
    },
    getQTDrugCount(state){ // tisdale için risk kategori 1 ve 2 sayılıyor.
       let count = 0; 
       
       state.patientDrugList.forEach(element => {
           if(element.crediblemed != null && element.riskcategory<3){
               count++;
           }}
       );
       return count;
    },
    getLoopDiuretics(state){
        let result = false;
        state.patientDrugList.forEach(element => {
            
            if(element.loopdiuretics==true){
                
                result = true;
            }}
        );
       return result;
    },
    getCategoryDrugCount: state => value =>{
        let count = 0;
        state.patientDrugList.forEach(element => {
            
            if(element.riskcategory == value){
                count++;
            }}
        );
       return count;
    
    }

}

const mutations = {
    sortPatientDrugList(state){
        state.patientDrugList.sort((a,b)=>{a.urunadi.localeCompare(b.urunadi)});
        

    },
    insertpatientdrugList(state, payLoad){
        let obj = state.patientDrugList.findIndex(o => o.uuid == payLoad.uuid)
        
        if(obj == -1){
            state.patientDrugList.push(payLoad);
        }
    },
    resetpatientdrugList(state){
        while(state.patientDrugList.length>0){
            state.patientDrugList.pop();
        }
    },
    deletepatientdrugList(state, payLoad){
        state.patientDrugList.splice(payLoad, 1);
    }
}

const actions = {
    newList({commit}){
        commit('resetpatientdrugList');
    },
    insertList({commit}, payLoad){
        commit('insertpatientdrugList', payLoad);
        commit('sortPatientDrugList');
    },
    deleteList({commit, state}, payLoad){
        let obj = state.patientDrugList.findIndex(o => o.uuid == payLoad)
        commit('deletepatientdrugList', obj);

    }
}

const patientdruglistModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default patientdruglistModule;