
const state = {
    age: null,
    sex: 0,
    QTInterval: null,
    potassium: null,
    calcium: null,
    CRPvalue: null,
    eGFR: null,
    acuteMI: 0,
    aryhtmia: 0,
    hearthfailure: 0,
    cardiomyopathia: 0,
    diabetes: 0,
    neurologicdiseases:0,
    liverfailure: 0,
    sepsis: 0,
    thyroiddisease: 0,
    tisdaleRiskScore: 0,
    tobacco: 0,
    BMI: null
}

const getters = {
    getAge: (state)=> {return state.age;},
    getSex: (state)=> { return state.sex; },
    getBMI: (state)=>{return state.BMI},
    getQTInterval: (state)=>{ return state.QTInterval},
    getPotassium: (state)=>{return state.potassium},
    getCalcium: (state)=>{ return state.calcium},
    getCRPvalue: (state)=>{return state.CRPvalue},
    geteGFR: (state)=>{ return state.eGFR},
    getAcuteMI: (state)=>{return state.acuteMI},
    getArythmia: (state)=>{
        if(state.aryhtmia == null){
            return 0;
        }
        return state.aryhtmia
    },
    getHypertension: (state)=>{ 
        if(state.hypertension==null){
            return 0;
        }
        return state.hypertension
    },
    getHeartFailure: (state)=> { 
        if(state.hearthfailure==null)
        {
            return 0;
        }
        return state.hearthfailure
    },
    getCardiomyopathia: (state)=>{
        if(state.cardiomyopathia==null){
            return 0;
        }
        return state.cardiomyopathia
    },
    getDiabetes: (state)=>{ 
        if(state.diabetes==null){
            return 0;
        }
        return state.diabetes
    },
    getNeurologicDiseases: (state)=>{
        if(state.neurologicdiseases==null){
            return 0;
        }
        return state.neurologicdiseases;
    },
    getLiverFailure: (state)=>{ 
        if(state.liverfailure==null){
            return 0;
        }
        return state.liverfailure
    },
    getThyroidDisease: (state)=>{ 
        if(state.thyroiddisease == null)
        {
            return 0;
        }
        return state.thyroiddisease
    
    },
    getSepsis: (state)=>{ 
        if(state.sepsis==null){
            return 0;
        }
        return state.sepsis
    },
    getTobacco: (state)=>{
        if(state.tobacco == null){
            return 0;
        }
        return state.tobacco
    },
    getTisdaleScore:(state, getters, rootState, rootGetters)=>{
        let score = 0 ;
        const sayi = rootGetters['patientdruglistModule/getQTDrugCount'];
        const loopdiuretic =rootGetters['patientdruglistModule/getLoopDiuretics'];

        if(state.age>=68){
            score += 1;
        }
        if(state.sex==0){
            score +=1;
        }

        if(state.QTInterval >= 450){
            score +=2;
        }

        if(state.acuteMI == 1){
            score +=2;
        }

        if(state.potassium <= 3.5 && (state.potassium != null) && state.potassium != ''){
            score +=2;
        }
        if(state.sepsis == 1){
            score +=3;
        }
        if(state.hearthfailure == 1){
            score +=3;
        }

        if(sayi==1){
            score +=3;
        }
        if(sayi>=2){
            score +=6;
        }

        if(loopdiuretic==true){
            
            score +=1;
        }
        return score;
    },
    getTisdaleScoreExplaination:(state, getters)=>{
        let result = 'Düşük risk';
        let skor = getters.getTisdaleScore;
        if(skor>=7 && skor<11){
            result = 'Orta risk';
        }
        if(skor>=11){
            result = 'Yüksek risk';
        }
        return result;
    },
    getRisqPathScore(state,getters, rootState, rootGetters){
        let score = 0;
        const categoryOne = rootGetters['patientdruglistModule/getCategoryDrugCount'](1);
        const categoryTwo = rootGetters['patientdruglistModule/getCategoryDrugCount'](2);
        const categoryThree = rootGetters['patientdruglistModule/getCategoryDrugCount'](3);

        if(state.age>=68){
            score += 3;
        }

        if(state.sex==0){
            score +=3;
        }

        if(state.tobacco==1){
            score +=1;
        }
        
        if(state.BMI>=30){
            score +=1;
        }

        if(state.cardiomyopathia==1){
            score +=3;
        }

        if(state.hypertension==1){
            score +=3;
        }

        if(state.aryhtmia ==1){
            score +=3;
        }
        
        if(state.QTInterval>=450 && state.sex==1) score +=6; // erkeklerde 450 ms üzeri ise
        if(state.QTInterval>=470 && state.sex==0) score +=6; // kadınlarda 470 ms üzeri ise
        
        if(state.thyroiddisease==1){
            score +=3;
        }
        if(state.liverfailure==1){
            score +=1;
        }

        if(state.neurologicdiseases==1){
            score +=0.5;
        }
        if(state.diabetes==1){
            score+=0.5;
        }

        if(state.potassium <= 3.5 && (state.potassium != null) && state.potassium != ''){
            score +=6;
        }

        if(state.calcium <= 2.15 && (state.calcium != null) && state.calcium != ''){
            score +=3;
        }

        if(state.CRPvalue >= 5 && (state.CRPvalue != null) && state.CRPvalue != ''){
            score +=1;
        }

        if(state.eGFR <= 30 && (state.eGFR != null) && state.eGFR != ''){
            score +=0.5;
        }

        score = score + categoryOne*3+categoryTwo*0.5+categoryThree*0.25;
        return score;
    },
    getRisqPathExplanation:(state,getters)=>{
        let result = 'Düşük risk';
        let skor = getters.getRisqPathScore;
        if(skor>=11){
            result = 'Orta/Yüksek risk';
        }
        return result;


    }


}

const mutations = {
    setAge(state, payLoad){
        state.age = payLoad;
    },
    setSex(state, payLoad){
        state.sex = payLoad;
    },
    setQTInterval(state, payLoad){
        state.QTInterval = payLoad;
    },
    setPotassium(state, payLoad){
        state.potassium = payLoad;
    },
    setCalcium(state, payLoad){
        state.calcium = payLoad;
    },
    setCRPvalue(state, payLoad){
        state.CRPvalue = payLoad;
    },
    seteGFR(state, payLoad){
        state.eGFR = payLoad;
    },
    setAcuteMI(state, payLoad){

        state.acuteMI = payLoad;
    },
    setArythmia(state, payLoad){
        
        state.aryhtmia = payLoad;
    },
    setHypertension(state, payLoad){
        state.hypertension = payLoad;
    },
    setHeartFailure(state, payLoad){
        state.hearthfailure = payLoad;
    },
    setCardiomyopathia(state, payLoad){
        state.cardiomyopathia = payLoad;
    },
    setDiabetes(state, payLoad){
        state.diabetes = payLoad;
    },
    setNeurologicDiseases(state, payLoad){
        state.neurologicdiseases = payLoad;
    },
    setLiverFailure(state, payLoad){
        state.liverfailure = payLoad;
    },
    setThyroidDisease(state, payLoad){
        state.thyroiddisease = payLoad;
    },
    setSepsis(state, payLoad){
        state.sepsis = payLoad;
    },
    setTobacco(state, payLoad){
        state.tobacco = payLoad;
    },
    setBMI(state, payLoad){
        state.BMI = payLoad;
    }
}

const actions = {
    updateAge({commit}, payLoad){
        commit('setAge', payLoad);
    },
    updateSex({commit}, payLoad){
        commit('setSex', payLoad);
    },
    updateQTInterval({commit}, payLoad){
        commit('setQTInterval', payLoad);
    },
    updatePotassium({commit}, payLoad){
        commit('setPotassium', payLoad);
    },
    updateCalcium({commit}, payLoad){
        commit('setCalcium', payLoad);
    },
    updateCRPvalue({commit}, payLoad){
        commit('setCRPvalue', payLoad);
    },
    updateeGFR({commit}, payLoad){
        commit('seteGFR', payLoad);
    },
    updateAcuteMI({commit}, payLoad){
        commit('setAcuteMI', payLoad);
    },
    updateArythmia({commit}, payLoad){
        commit('setArythmia', payLoad);
    },
    updateHypertension({commit}, payLoad){
        commit('setHypertension', payLoad);
    },
    updateHeartFailure({commit}, payLoad){
        commit('setHeartFailure', payLoad);
    },
    updateCardiomyopathia({commit}, payLoad){
        commit('setCardiomyopathia', payLoad);
    },
    updateDiabetes({commit}, payLoad){
        commit('setDiabetes', payLoad);
    },
    updateNeurologicDiseases({commit}, payLoad){
        commit('setNeurologicDiseases', payLoad);
    },
    updateLiverFailure({commit}, payLoad){
        commit('setLiverFailure', payLoad);
    },
    updateThyroidDisease({commit}, payLoad){
        commit('setThyroidDisease', payLoad);
    },
    updateSepsis({commit}, payLoad){
        commit('setSepsis', payLoad);
    },
    updateTobacco({commit}, payLoad){
        commit('setTobacco', payLoad)
    },
    updateBMI({commit}, payLoad){
        commit('setBMI', payLoad)
    }

}

const calculationModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default calculationModule;