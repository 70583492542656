<template>
  <div class="flex flex-col justify-between h-screen">
    <app-header></app-header>
    <div class="mb-auto ">
      <router-view/>
    </div>
    
    <app-footer></app-footer>
  </div>
  
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    appHeader: Header,
    appFooter: Footer
      }
}
</script>


