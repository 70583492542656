import api from '../api'
import router from '@/router'
const state = {
    selectedUrun: null,
    searchText: '',
    ruhsatliurunList:[],
    errorMessage: ''
}


const getters = {
    getselectedUrun(state){
        return state.selectedUrun;
    },
    getruhsatliurunList(state){
        return state.ruhsatliurunList;

    },
    getsearchText(state){
        return state.searchText;
    },
    geterrorMessage(state){
        return state.errorMessage;
    }
}

const mutations = {
    setselectedUrun(state, payLoad){
        state.selectedUrun = payLoad;
    },
    resetruhsatliurunList(state){
        while(state.ruhsatliurunList.length>0){
            state.ruhsatliurunList.pop();
        }
    },
    insertruhsatliurunList(state, payLoad){
        state.ruhsatliurunList.push(payLoad);
        
    },
    setsearchText(state, payLoad){
        state.searchText = payLoad;
    },
    seterrorMessage(state, payLoad){
        state.errorMessage = payLoad;
    }
}

const actions = {
    getRuhsatliUrunler({rootGetters, state, commit}){
        let dbquery = ''
        if(state.searchText != ''){
            dbquery = state.searchText;
        }
        else{
            dbquery = 'ab'
        }
        let token = rootGetters['userModule/getToken'];
        api.get(`ruhsatliurunler/?dbquery=${dbquery}`, { headers: {"Authorization" : `Bearer ${token}`} }).then(response =>{
            if(response.status == 200){
                commit('resetruhsatliurunList');
                response.data.forEach(element => {
                    commit('insertruhsatliurunList', element);
                    commit('seterrorMessage', null);
                });
            }

        }).catch(error => {
            

            commit('seterrorMessage', error.response.data.detail);
            if(error.response.status==401){
                router.push({name: 'Login'})
            }
            
        });
    },
    setSearchTerm({commit,dispatch}, payLoad){
        commit('setsearchText', payLoad);
        dispatch('getRuhsatliUrunler');
    },
    updateSelectedUrun({commit,dispatch}, payLoad){
        commit('setselectedUrun', payLoad)
        dispatch('patientdruglistModule/insertList', payLoad, {root:true});
        dispatch('updateToggleModal', false,{root:true});
    }

}

const ruhsatliurunModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}



export default ruhsatliurunModule;